<template>
    <SearchLayout display-current-url display-current-position :get-data="searchByDomain" inner-hits-key="prompts_hits">
        <template #filters="{ search, forceSubmit }">
            <nl-search-filters
                input-key="urlSearch"
                name="site"
                :placeholder="$t('searchform.enterdomain')"
                url-search
                @search="search"
                :force-submit="forceSubmit"
            />
        </template>
        <template #errors="{ props: { filters, loading, hasError } }">
            <div v-if="loading" class="box p-5" style="position: relative">
                <b-loading :active="loading" :is-full-page="false" />
            </div>
            <b-notification v-else-if="hasError" active :closable="false" type="is-danger is-light">
                {{ $t('error.message.default') }}
            </b-notification>
            <b-notification v-else-if="filters?.urlSearch" active :closable="false" type="is-info is-light">
                {{ $t('searchform.noarticlesurl') }}
            </b-notification>
            <b-notification v-else active :closable="false" type="is-warning is-light">
                {{ $t('searchform.enter-domain') }}
            </b-notification>
        </template>
    </SearchLayout>
</template>
<script>
    import NlSearchFilters from '@/components/Search/NlSearchFilters'
    import { mapActions } from 'vuex'
    import SearchLayout from '@/components/Search/SearchLayout.vue'

    export default {
        title: 'searchform.searchbydomain',
        name: 'SearchByDomain',
        components: {
            SearchLayout,
            NlSearchFilters
        },
        data: () => ({
            domain: null
        }),
        methods: {
            ...mapActions('search', ['searchByDomain'])
        }
    }
</script>
